var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('validation-observer',{ref:"saveFormRef",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('b-form',[_c('b-row',[_c('b-col',{attrs:{"cols":"8"}},[_c('b-form-group',{attrs:{"label-for":"name-course"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Tên khóa học "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]},proxy:true}],null,true)},[_c('validation-provider',{attrs:{"name":"Tên khóa học","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"name-course","name":"nameCourse","placeholder":"Tên khóa học","state":_vm.getElementState(errors),"aria-describedby":"input-1-live-feedback"},model:{value:(_vm.targetData.name),callback:function ($$v) {_vm.$set(_vm.targetData, "name", $$v)},expression:"targetData.name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"cols":"4"}},[_c('b-form-group',{attrs:{"label-for":"code-course"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Mã khóa học "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]},proxy:true}],null,true)},[_c('validation-provider',{attrs:{"name":"Mã khóa học","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"code-course","name":"codeCourse","placeholder":"Mã khóa học","state":_vm.getElementState(errors),"aria-describedby":"input-1-live-feedback"},model:{value:(_vm.targetData.code),callback:function ($$v) {_vm.$set(_vm.targetData, "code", $$v)},expression:"targetData.code"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"cols":"6"}},[_c('b-form-group',{attrs:{"label-for":"startDate"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Ngày bắt đầu "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]},proxy:true}],null,true)},[_c('validation-provider',{attrs:{"name":"Ngày bắt đầu","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-datepicker',{attrs:{"id":"startDate","date-format-options":{ year: 'numeric', month: 'numeric', day: 'numeric' },"locale":"vi","format":"dd/mm/yyyy"},model:{value:(_vm.targetData.startDate),callback:function ($$v) {_vm.$set(_vm.targetData, "startDate", $$v)},expression:"targetData.startDate"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"cols":"6"}},[_c('b-form-group',{attrs:{"label":"Ngày kết thúc","label-for":"endDate"}},[_c('b-form-datepicker',{attrs:{"id":"endDate","date-format-options":{ year: 'numeric', month: 'numeric', day: 'numeric' },"locale":"vi","format":"dd/mm/yyyy"},model:{value:(_vm.targetData.endDate),callback:function ($$v) {_vm.$set(_vm.targetData, "endDate", $$v)},expression:"targetData.endDate"}})],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label-for":"trainingSystems"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Chương trình đào tạo/Bồi dưỡng "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]},proxy:true}],null,true)},[_c('validation-provider',{attrs:{"name":"Chương trình đào tạo/Bồi dưỡng","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"options":_vm.trainingSystems,"reduce":function (option) { return option.value; },"state":_vm.getElementState(errors)},model:{value:(_vm.targetData.trainingSystemId),callback:function ($$v) {_vm.$set(_vm.targetData, "trainingSystemId", $$v)},expression:"targetData.trainingSystemId"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label-for":"trainingTypeId"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Loại chương trình "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]},proxy:true}],null,true)},[_c('validation-provider',{attrs:{"name":"Loại chương trình","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"options":_vm.trainingTypes,"reduce":function (option) { return option.value; },"state":_vm.getElementState(errors)},model:{value:(_vm.targetData.trainingTypeId),callback:function ($$v) {_vm.$set(_vm.targetData, "trainingTypeId", $$v)},expression:"targetData.trainingTypeId"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"cols":"6"}},[_c('b-form-group',{attrs:{"label":"Số thứ tự","label-for":"status"}},[_c('b-form-input',{attrs:{"id":"order_no","type":"number"},model:{value:(_vm.targetData.orderNo),callback:function ($$v) {_vm.$set(_vm.targetData, "orderNo", $$v)},expression:"targetData.orderNo"}})],1)],1),_c('b-col',{attrs:{"cols":"6"}},[_c('b-form-group',{attrs:{"label-for":"status"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Trạng thái "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]},proxy:true}],null,true)},[_c('validation-provider',{attrs:{"name":"Trạng thái","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"options":_vm.statuses,"reduce":function (option) { return option.value; },"state":_vm.getElementState(errors)},model:{value:(_vm.targetData.status),callback:function ($$v) {_vm.$set(_vm.targetData, "status", $$v)},expression:"targetData.status"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('div',{staticClass:"float-left"},[_c('b-button',{staticClass:"mr-1",attrs:{"type":"submit","variant":"primary","disabled":invalid},on:{"click":_vm.handleSaveData}},[_vm._v(" Lưu ")])],1)])],1)],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }