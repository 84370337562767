<template>
  <validation-observer
    ref="saveFormRef"
    #default="{invalid}"
  >
    <b-form>
      <b-row>
        <!-- Name Course -->
        <b-col cols="8">
          <b-form-group label-for="name-course">
            <template v-slot:label>
              Tên khóa học <span class="text-danger">*</span>
            </template>
            <validation-provider
              #default="{ errors }"
              name="Tên khóa học"
              rules="required"
            >
              <b-form-input
                id="name-course"
                v-model="targetData.name"
                name="nameCourse"
                placeholder="Tên khóa học"
                :state="getElementState(errors)"
                aria-describedby="input-1-live-feedback"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col cols="4">
          <b-form-group label-for="code-course">
            <template v-slot:label>
              Mã khóa học <span class="text-danger">*</span>
            </template>
            <validation-provider
              #default="{ errors }"
              name="Mã khóa học"
              rules="required"
            >
              <b-form-input
                id="code-course"
                v-model="targetData.code"
                name="codeCourse"
                placeholder="Mã khóa học"
                :state="getElementState(errors)"
                aria-describedby="input-1-live-feedback"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col cols="6">
          <b-form-group label-for="startDate">
            <template v-slot:label>
              Ngày bắt đầu <span class="text-danger">*</span>
            </template>
            <validation-provider
              #default="{ errors }"
              name="Ngày bắt đầu"
              rules="required"
            >
              <b-form-datepicker
                id="startDate"
                v-model="targetData.startDate"
                :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                locale="vi"
                format="dd/mm/yyyy"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        
        <b-col cols="6">
          <b-form-group
            label="Ngày kết thúc"
            label-for="endDate"
          >
            <b-form-datepicker
              id="endDate"
              v-model="targetData.endDate"
              :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
              locale="vi"
              format="dd/mm/yyyy"
            />
          </b-form-group>
        </b-col>
        
        <b-col cols="12">
          <b-form-group label-for="trainingSystems">
            <template v-slot:label>
              Chương trình đào tạo/Bồi dưỡng <span class="text-danger">*</span>
            </template>
            <validation-provider
              #default="{ errors }"
              name="Chương trình đào tạo/Bồi dưỡng"
              rules="required"
            >
              <v-select
                v-model="targetData.trainingSystemId"
                :options="trainingSystems"
                :reduce="option => option.value"
                :state="getElementState(errors)"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col cols="12">
          <b-form-group label-for="trainingTypeId">
            <template v-slot:label>
              Loại chương trình <span class="text-danger">*</span>
            </template>
            <validation-provider
              #default="{ errors }"
              name="Loại chương trình"
              rules="required"
            >
              <v-select
                v-model="targetData.trainingTypeId"
                :options="trainingTypes"
                :reduce="option => option.value"
                :state="getElementState(errors)"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col cols="6">
          <b-form-group
            label="Số thứ tự"
            label-for="status"
          >
            <b-form-input
              id="order_no"
              v-model="targetData.orderNo"
              type="number"
            />
          </b-form-group>
        </b-col>
        <b-col cols="6">
          <b-form-group label-for="status">
            <template v-slot:label>
              Trạng thái <span class="text-danger">*</span>
            </template>
            <validation-provider
              #default="{ errors }"
              name="Trạng thái"
              rules="required"
            >
              <v-select
                v-model="targetData.status"
                :options="statuses"
                :reduce="option => option.value"
                :state="getElementState(errors)"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col cols="12">
          <div class="float-left">
            <b-button
              type="submit"
              variant="primary"
              class="mr-1"
              :disabled="invalid"
              @click="handleSaveData"
            >
              Lưu
            </b-button>
          </div>
        
        </b-col>
      </b-row>
    </b-form>
  </validation-observer>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import {
  BForm,
  BButton,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormDatepicker,
} from 'bootstrap-vue'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { required } from '@validations'
import vSelect from 'vue-select'
import { getUser } from '@/auth/utils'
import {STATUSES, TRAINING_TYPES} from '@/const/status'

export default {
  name: 'FormCourse',
  components: {
    vSelect,
    ValidationObserver,
    ValidationProvider,
    BForm,
    BButton,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormDatepicker,
  },
  props: {
    dataCourse: {
      type: Object,
      default: () => {
      },
    },
  },
  data() {
    return {
      user: getUser(),
      formErr: {
        nameCourse: '',
        maHocKy: '',
        startDate: '',
        endDate: '',
      },
      statuses: STATUSES,
      required,
      targetData: {},
    }
  },
  computed: {
    // calculate data from init data
    ...mapGetters( {
      trainingSystems: 'dropdown/trainingSystems',
      maxOrderNo: 'course/maxOrderNo',
      scoringFormulas: 'course/scoringFormulas',
    } ),
    trainingTypes() {
      return TRAINING_TYPES
    },
  },
  async created() {
    // init data FROM API
    if ( this.dataCourse ) {
      this.targetData = { ...this.dataCourse }
    }
    
    if ( !this.targetData.trainingSystem ) {
      this.targetData.trainingSystem = this.trainingSystems[0].value
    }
    if ( !this.targetData.status ) {
      this.targetData.status = 1
    }
    if ( !this.targetData.code ) {
      this.targetData.code = ''
    }
    if ( !this.targetData.orderNo ) {
      await this.getMaxOrderNo( { organizationId: getUser().orgId } )
      this.targetData.orderNo = this.maxOrderNo + 1
    }
    this.$set( this, 'targetData', { ...this.targetData } )
  },
  methods: {
    ...mapActions( {
      getMaxOrderNo: 'course/getMaxOrderNo',
      getTrainingSystems: 'dropdown/getTrainingSystems',
    } ),
    // handle even
    handleSaveData( e ) {
      const data = this.targetData
      this.$emit( 'saveDataCourse', data )
      e.preventDefault()
    },
    
    resetForm() {
      this.$nextTick( () => {
        this.$refs.observer.reset()
      } )
    },
    getElementState( errors ) {
      return errors.length > 0 ? false : null
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
